// utils.js

// Module-scoped variables
let proxy = "/WebInterface/";
proxy = proxy.substring(0, proxy.length - ("/Web" + "Interface/").length);
const apiURL = "/WebInterface/function/";

// Exported ajax object
export const ajax = {
  getScript: (source, callback, catchCallBack) => {
    var script = document.createElement("script");
    var prior = document.getElementsByTagName("script")[0];
    script.async = 1;
    script.onload = script.onreadystatechange = (_, isAbort) => {
      if (
        isAbort ||
        !script.readyState ||
        /loaded|complete/.test(script.readyState)
      ) {
        script.onload = script.onreadystatechange = null;
        script = undefined;
        if (!isAbort) {
          if (callback) callback();
        }
      }
    };

    script.onerror = () => {
      catchCallBack();
    };

    script.src = source;
    prior.parentNode.insertBefore(script, prior);
  },
  iframeSubmit: (opt, requestType, toRemove) => {
    const uid = "i" + utils.getUId();
    const iframe = utils.generateHTML([
      {
        type: "iframe",
        attrs: {
          id: uid,
          name: uid,
          src: "javascript:false;",
          style: "display: none;",
        },
      },
    ]);

    document.body.appendChild(iframe);
    const _$ = utils.getDomInstance();
    _$("#crushftp_action").remove();
    const actionForm = utils.generateHTML([
      {
        type: "form",
        attrs: {
          id: "crushftp_action",
          name: "crushftp_action",
          enctype: "multipart/form-data",
          method: "POST",
        },
        children: [
          {
            type: "input",
            attrs: {
              type: "text",
              id: "command",
              name: "command",
              value: "",
            },
          },
          {
            type: "input",
            attrs: {
              type: "text",
              id: "path",
              name: "path",
              value: "",
            },
          },
          {
            type: "input",
            attrs: {
              type: "text",
              id: "paths",
              name: "paths",
              value: "",
            },
          },
        ],
      },
    ]);
    document.body.appendChild(actionForm);
    var formToSubmit = _$("#crushftp_action");
    if (requestType) {
      formToSubmit.attr("method", requestType);
    }
    formToSubmit.attr("action", apiURL);
    formToSubmit.attr("target", uid);
    formToSubmit
      .find("input")
      .sel()
      .forEach((input) => (input.value = ""));
    for (var key in opt) {
      if (opt.hasOwnProperty(key)) {
        if (!formToSubmit.find(key, true)) {
          formToSubmit.append(
            '<input type="text" id="' +
              key.replace("#", "") +
              '" name="' +
              key.replace("#", "") +
              '" value="' +
              opt[key] +
              '" />'
          );
        } else {
          formToSubmit.find(key, true).value = opt[key];
        }
      }
    }
    formToSubmit.append(
      '<input type="text" id="c2f" name="c2f" value="' +
        utils.cookie.get("currentAuth") +
        '" />'
    );
    if (toRemove) {
      var items = toRemove.split(",");
      for (var i = 0; i < items.length; i++) {
        formToSubmit.find("#" + items[i].trim() + "").remove();
      }
    }
    var agent = navigator.userAgent.toLowerCase();
    if (
      agent.indexOf("android") >= 0 &&
      agent.indexOf("chrome") >= 0 &&
      opt["#command"] &&
      opt["#command"].indexOf("download") >= 0
    ) {
      formToSubmit.attr("target", "_blank");
    }
    formToSubmit.el().submit();
    setTimeout(() => {
      formToSubmit.el().remove();
      iframe.remove();
    }, 120000);
  },
  load: (url, el, cache) => {
    if (cache && _cache[url]) {
      if (el.isCrushElem) el.html(_cache[url]);
      else if (el.innerHTML) el.innerHTML = _cache[url];
      return;
    }
    fetch(url).then((res) => {
      if (el.isCrushElem) el.html(res);
      else if (el.innerHTML) el.innerHTML = res;
    });
  },
  buildFormData: (data) => {
    return Object.entries(data).reduce(
      (d, e) => (d.append(...e), d),
      new FormData()
    );
  },
  call: ({
    url = apiURL,
    method = "post",
    data = {},
    headers = {},
    contentType = {},
    options = {},
    urlEncoded = false,
    timeout = 60 * 1000,
  }) => {
    let _data;
    if (!urlEncoded) {
      const c2f = utils.cookie.get("currentAuth");
      if (!data.c2f) data.c2f = c2f;
      if (options.skipC2F) delete data.c2f;
      if (!options.skipRandom) data.random = Math.random();
      _data = ajax.buildFormData(data);
    } else {
      _data = data;
    }
    return reqwest({
      url,
      method,
      data: _data,
      headers,
      contentType,
      processData: urlEncoded,
      timeout,
    });
  },
};
